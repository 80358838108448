<template>
  <div class="account-plan-submenu-wrapper">
    <b-row class="submenu-container dark mb-4" no-gutters>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'incomes' }" @click.prevent="redirectTab('incomes')">
          {{ $t(translationPath + 'incomes') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'expenses' }" @click.prevent="redirectTab('expenses')">
          {{ $t(translationPath + 'expenses') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'assets' }" @click.prevent="redirectTab('assets')">
          {{ $t(translationPath + 'assets') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'liabilities' }" @click.prevent="redirectTab('liabilities')">
          {{ $t(translationPath + 'liabilities') }}
        </b-link>
        <span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'by-name' }" @click.prevent="redirectTab('by-name')">
          {{ $t('reports.other.account_plan.all_in_one_table') }}
        </b-link><span class="mx-3 divider">|</span>
      </b-col>
      <b-col md="auto">
        <b-link href="javascript:void(0)" :class="{ disabled: active === 'budget' }" @click.prevent="redirectTab('budget')">
          {{ $t('reports.other.account_plan.budget_subtab_title') }}
        </b-link>
      </b-col>
    </b-row>

    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onConfirmed"
    ></confirm-modal>
  </div>
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal'

export default {
  name: 'AccountPlanSubmenu',
  components: { ConfirmModal },
  props: {
    hasUnsavedChanges: {
      type: Boolean,
      default: false
    },
    active: {
      type: String,
      default: 'incomes'
    }
  },
  data () {
    return {
      translationPath: 'common.',
      baseUrl: '/reports/other/account-plan-v2/',
      to: ''
    }
  },
  methods: {
    redirectTab (value) {
      if (this.active === value) {
        return false
      }
      this.to = this.baseUrl + value

      if (this.hasUnsavedChanges) {
        this.$refs.ConfirmModal.show()
      } else {
        this.onConfirmed()
      }
    },
    onConfirmed () {
      this.$router.push(this.to)
      if (typeof this.$refs.ConfirmModal !== 'undefined') {
        this.$refs.ConfirmModal.hide()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
</style>
