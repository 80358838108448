<template>
  <b-modal
    id="deleteModalNetworth"
    centered
    ref="deleteModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t(translationPath + 'title_networth') }}
    </template>
    <p> {{ $t(translationPath + 'message_networth') }} </p>
    <template slot="modal-footer" class="text-center">
      <b-button variant="primary" v-on:click="onOk">{{ $t('common.ok_close')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteNetworthAccountModal',
  data () {
    return {
      translationPath: 'common.modals.delete.'
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.deleteModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.deleteModal.hide()
      this.$emit('hide')
    },
    onOk () {
      this.$refs.deleteModal.hide()
      this.$emit('on-ok-close')
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
