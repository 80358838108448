<template>
  <b-modal
    id="deleteAccountGroupModal"
    centered
    ref="deleteModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t('common.modals.delete.title_account_group', { account_name: itemName })}}
    </template>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancel">{{ $t('common.no_cancel')}}</b-button>
      <b-button variant="danger"  @click="onYes">{{ $t('common.yes_delete')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteAccountGroupModal',
  props: ['itemName'],
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.deleteModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.deleteModal.hide()
      this.$emit('hide')
    },
    onCancel () {
      this.$refs.deleteModal.hide()
      this.$emit('on-delete-cancel')
    },
    onYes () {
      this.$emit('on-delete-confirm')
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
