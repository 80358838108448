import axios from 'axios'
import { mapState } from 'vuex'
import AccountPlanSubmenu from './AccountPlanSubmenu'
import titleMixins from '@/mixins/title'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'
import formatAmount from '@/assets/filters/formatAmount'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import Loader from '@/components/common/Loader'
import ConfirmModal from '@/components/modals/ConfirmModal'
import AccountPlanOverviewTable from './AccountPlanOverviewTable'

export default {
  components: { AccountPlanSubmenu, Loader, ConfirmModal, AccountPlanOverviewTable },
  mixins: [titleMixins, useComponentCurrencyInputMixin],
  filters: {
    formatAmount,
    displayAccountTitle
  },
  data () {
    return {
      renderKey: 0,
      translationPath: 'reports.other.account_plan.',
      busy: true,
      accounts: null,
      saveDisabled: false,
      accountsGrouped: {
        income: [],
        expense: [],
        asset: [],
        liability: []
      },
      globalAccountsGrouped: {
        income: [],
        expense: [],
        asset: [],
        liability: []
      },
      accountGroups: {
        income: [],
        expense: [],
        asset: [],
        liability: []
      },
      next: null,
      isFormDisabled: true,
      hasUnsavedChanges: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.manage_title'), to: '/reports/other/account-plan-v2/incomes' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t('reports.other._common.account_plan_report')
    },
    local () {
      return this.currentCOA.locale
    },
    /**
     * Options for select input with yes/no values.
     * Todo: maybe move it to common mixin for all project, because it can be used anywhere.
     */
    yesNoOptions () {
      return [
        { value: 1, text: this.$t('common.yes') },
        { value: 0, text: this.$t('common.no') }
      ]
    },
    /**
     * Options for select input - free or tied capital.
     */
    freeTiedCapitalOptions () {
      return [
        { value: 1, text: this.$t('assets_liabilities.create_account_wizard.assets.step3.free_capital') },
        { value: 0, text: this.$t('assets_liabilities.create_account_wizard.assets.step3.tied_capital') }
      ]
    }
  },
  methods: {
    sortCompare (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      const a = aRow[key]
      const b = bRow[key]
      if (key === 'group') {
        return a.title < b.title ? -1 : 1
      } else {
        return a < b ? -1 : 1
      }
    },
    async loadAccounts (accountTypes) {
      await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=${accountTypes}&limit=10000&sort[0][key]=type&sort[0][direction]=asc&sort[1][key]=title&sort[1][direction]=asc`)
        .then((response) => {
          this.accounts = response.data.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    loadGlobalAccounts (accountTypes) {
      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/global?account_type=${accountTypes}`)
        .then((response) => {
          response.data.data.accounts.map((group) => {
            this.globalAccountsGrouped[group.type].push(group)
          })
        })
        .catch((error) => {
          console.error(error)
        })
      return true
    },
    async loadAccountGroups (accountType) {
      // If not loaded, then load
      if ((Object.prototype.hasOwnProperty.call(this.accountsGrouped, accountType) && this.accountsGrouped[accountType] !== null) === false || this.accountsGrouped[accountType].length === 0) {
        await this.loadAccountGroupsWithData(accountType)
        for (const index in this.accountsGrouped[accountType]) {
          if (this.accountsGrouped[accountType][index].is_account_group) {
            this.accountGroups[accountType].push(this.accountsGrouped[accountType][index])
          }
        }
        this.accountsGrouped[accountType].map(account => {
          // account.expected_annual_return_for_input = account.expected_annual_return ? account.expected_annual_return * 100 : null
          account.expected_annual_return_for_input = account.expected_annual_return ?? null
        })
        this.renderKey++
      } else {
        this.accountGroups = this.accountsGrouped[accountType]
      }
      return this.accountGroups
    },
    async loadAccountGroupsWithData (sAccountType, sApiUrlParam = '') {
      try {
        // Prepare for api request
        const iYear = new Date().getFullYear()
        let sApiRequestParams = ''
        if (sAccountType === 'income' || sAccountType === 'expense') {
          sApiRequestParams = '&include_amounts=1'
        } else if (sAccountType === 'asset' || sAccountType === 'liability') {
          sApiRequestParams = '&include_balance_amounts=1'
        }
        sApiRequestParams += sApiUrlParam

        const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=${sAccountType}${sApiRequestParams}&start_date=${iYear}-01-01&end_date=${iYear}-12-31&log=AccountPlanTabMixin`)
        const aGroups = response.data.data
        const self = this
        self.accountsGrouped[sAccountType] = []

        Object.keys(aGroups).forEach(function (key) {
          aGroups[key].is_account_group = true
          aGroups[key].is_account = false
          aGroups[key].amount = 0

          self.accountsGrouped[sAccountType].push(aGroups[key])

          const accounts = []
          Object.keys(aGroups[key].accounts).forEach(function (iAccountId, keys) {
            aGroups[key].accounts[iAccountId].is_account_group = false
            aGroups[key].accounts[iAccountId].is_account = true
            aGroups[key].accounts[iAccountId].amount_unformatted = parseFloat(aGroups[key].accounts[iAccountId].amount)
            aGroups[key].accounts[iAccountId].amount = parseFloat(aGroups[key].accounts[iAccountId].amount)

            accounts.push(aGroups[key].accounts[iAccountId])
            if (Object.keys(aGroups[key].accounts).length - 1 === keys) {
              self.accountsGrouped[sAccountType] = [...self.accountsGrouped[sAccountType], ...accounts]
            }
          })
        })

        setTimeout(() => {
          this.isFormDisabled = false
        }, 1000)
      } catch (error) {
        console.error(error)
      }
    },
    formatAmount (amount) {
      if (this.currentCOA.locale) {
        amount = formatAmount(amount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 2)
      }
      return amount
    },
    createTableDataWithEmptyRows (aReportAccounts) {
      let bFirst = true
      const aOutputTableData = []
      const oEmptyRow = { is_total_sum_row: false, is_part_sum_row: false, is_empty_row: true, is_account: false, is_account_group: false, title: '', date: '', amount: 0, actions: '' }
      const oPartSumRow = { is_total_sum_row: false, is_part_sum_row: true, is_empty_row: false, is_account: false, is_account_group: false, title: '', date: '', amount: 0, actions: '' }
      const oTotalSumRow = { is_total_sum_row: true, is_empty_row: false, is_account: false, is_account_group: false, title: '', date: '', amount: 0, actions: '' }
      let iPartSum = 0
      let iTotalSum = 0

      for (const index in aReportAccounts) {
        // Add empty row before account groups except for first row.
        if (aReportAccounts[index].is_account_group === true) {
          if (bFirst) {
            bFirst = false
          } else {
            oPartSumRow.amount = iPartSum// formatAmount(iPartSum, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0)

            aOutputTableData.push({ ...oPartSumRow })
            aOutputTableData.push(oEmptyRow)
            iPartSum = 0
          }
        }
        if (aReportAccounts[index].is_account === true) {
          iPartSum = iPartSum + aReportAccounts[index].amount_unformatted
          iTotalSum = iTotalSum + aReportAccounts[index].amount_unformatted
        }
        aOutputTableData.push(aReportAccounts[index])
      }

      // Last group
      oPartSumRow.amount = iPartSum // formatAmount(iPartSum, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0)
      aOutputTableData.push({ ...oPartSumRow })
      aOutputTableData.push(oEmptyRow)

      // Add total row
      oTotalSumRow.amount = iTotalSum // formatAmount(iTotalSum, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0)
      aOutputTableData.push(oTotalSumRow)

      return aOutputTableData
    },
    trClass (item) {
      if (item && item.is_account_group === true) {
        return 'tr-group'
      } else if (item && item.is_account === true) {
        return 'tr-account'
      } else if (item && item.is_empty_row === true) {
        return 'bg-white'
      } else if (item && item.is_total_sum_row === true) {
        return 'total-sum'
      } else if (item && item.is_part_sum_row === true) {
        return 'part-sum'
      }
      return ''
    },
    onTabActivate (newTabIndex, prevTabIndex, bvEvent) {
      bvEvent.preventDefault() // Disable tab switching, pushing to route instead
    },
    onChange () {
      if (!this.isFormDisabled) {
        this.hasUnsavedChanges = true
      }
    },
    onLeavePageConfirmed () {
      this.hasUnsavedChanges = false
      this.next()
      this.next = null
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.hasUnsavedChanges) {
      next()
    } else {
      this.next = next
      this.$refs.ConfirmModal.show()
    }
  }
}
