<template>
    <div>
        <b-tab
            :title="$t(translationPath + 'tab_title_liability_overview')"
            :active="$route.name === 'account-plan-liabilities-overview'"
            @click="clickHandler({ name: 'account-plan-liabilities-overview' })"
        >
            <slot name="account-plan-liabilities-overview"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_liabilities_values')"
          :active="$route.name === 'account-plan-liabilities-values'"
          @click="clickHandler({ name: 'account-plan-liabilities-values' })"
        >
          <slot name="account-plan-liabilities-values"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_misc')"
          :active="$route.name === 'account-plan-liabilities-misc'"
          @click="clickHandler({ name: 'account-plan-liabilities-misc' })"
        >
          <slot name="account-plan-liabilities-misc"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_liability_categorize')"
            :active="$route.name === 'account-plan-liabilities-categorization'"
            @click="clickHandler({ name: 'account-plan-liabilities-categorization' })"
        >
            <slot name="account-plan-liabilities-categorization"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_four_bucket_settings')"
            :active="$route.name === 'account-plan-liabilities-four-buckets'"
            @click="clickHandler({ name: 'account-plan-liabilities-four-buckets' })"
        >
            <slot name="account-plan-liabilities-four-buckets"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_match_to_globals')"
            :active="$route.name === 'account-plan-liabilities-match-globals'"
            @click="clickHandler({ name: 'account-plan-liabilities-match-globals' })"
        >
            <slot name="account-plan-liabilities-match-globals"></slot>
        </b-tab>
      <b-tab v-if="checkDev"
          :title="$t(translationPath + 'tab_title_tink')"
          :active="$route.name === 'account-plan-liabilities-tink'"
          @click="clickHandler({ name: 'account-plan-liabilities-tink' })"
      >
          <slot name="account-plan-liabilities-tink"></slot>
      </b-tab>
      <b-tab v-if="checkDev"
          :title="$t(translationPath + 'tab_title_update_values')"
          :active="$route.name === 'account-plan-liabilities-update-values'"
          @click="clickHandler({ name: 'account-plan-liabilities-update-values' })"
      >
          <slot name="account-plan-liabilities-update-values"></slot>
      </b-tab>
      <b-tab
          :title="$t(translationPath + 'tab_title_account_ownership')"
          :active="$route.name === 'account-plan-liabilities-ownership'"
          @click="clickHandler({ name: 'account-plan-liabilities-ownership' })"
      >
          <slot name="account-plan-ownership-global"></slot>
      </b-tab>
      <b-tab
        :title="$t(translationPath + 'tab_title_stats')"
        :active="$route.name === 'account-plan-liabilities-stats'"
        @click="clickHandler({ name: 'account-plan-liabilities-stats' })"
      >
        <slot name="account-plan-stats-liabilities"></slot>
      </b-tab>
    </div>
</template>

<script>
export default {
  name: 'LiabilityMenu',
  data () {
    return {
      translationPath: 'reports.other.account_plan.'
    }
  },
  computed: {
    checkDev () {
      return process.env.VUE_APP_NODE_ENV === 'development'
    }
  },
  methods: {
    clickHandler ({ name }) {
      if (this.$route.name === name) return true
      this.$router.push({ name })
    }
  }
}
</script>
