<template>
  <b-modal
    id="confirmModal"
    centered
    ref="confirmModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t(title) }}
    </template>
    <p class="mb-0">{{ $t(message) }}</p>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancel">{{ $t(noCaption) }}</b-button>
      <b-button :variant="variant"  @click="onYes">{{ $t(yesCaption) }}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String,
      default: 'Please confirm'
    },
    message: {
      type: String,
      default: 'Do you really want to do this?'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    yesCaption: {
      type: String,
      default: 'common.yes'
    },
    noCaption: {
      type: String,
      default: 'common.no'
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.confirmModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.confirmModal.hide()
      this.$emit('hide')
    },
    onCancel () {
      this.$refs.confirmModal.hide()
      this.$emit('not-confirmed')
    },
    onYes () {
      this.$emit('confirmed')
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
