<template>
  <b-modal
    id="accountGroupPreventModal"
    centered
    ref="deleteModalPrevent"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t('common.modals.prevent.heading_account_group', { name: itemName })}}
    </template>
    <p> {{ $t('common.modals.prevent.message_account_group') }} </p>
    <template slot="modal-footer" class="text-center">
      <b-button variant="primary" @click="hide">{{ $t('common.ok')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AccountGroupPreventModal',
  props: ['itemName'],
  methods: {
    onShow () {
      this.$emit('show')
    },
    onHide () {
      this.$emit('hide')
    },
    show () {
      this.$refs.deleteModalPrevent.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.deleteModalPrevent.hide()
      this.$emit('hide')
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
