import axios from 'axios'
import DeleteNetworthAccountModal from '@/components/modals/DeleteNetworthAccountModal'
import DeleteAccountGroupModal from '@/components/modals/DeleteAccountGroupModal'
import AccountGroupPreventDelete from '@/components/modals/AccountGroupPreventDelete'
import DeleteAccountModal from '@/components/modals/DeleteAccountModal'

export default {
  name: 'AccountPlanActionsMixin',
  components: {
    AccountGroupPreventDelete,
    DeleteAccountModal,
    DeleteNetworthAccountModal,
    DeleteAccountGroupModal
  },
  data () {
    return {
      iDestroyAccountId: null,
      iDestroyAccountGroupId: null,
      deleteAccountName: null,
      deleteAccountType: null,
      deleteAccountGroupId: null,
      deleteAccountGroupType: null,
      deleteAccountGroupName: null,
      onDeleteMoveTransactions: false
    }
  },
  methods: {
    onOpenAccount (item) {
      if (item.type === 'expense' || item.type === 'income') {
        this.$router.push('/reports/performance/accounts/' + item.id + '/view')
      } else if (item.type === 'asset' || item.type === 'liability') {
        this.$router.push('/reports/performance/accounts/' + item.id + '/view')
      }
    },
    fetchHouseHolds () {
      return axios
        .get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/persons`)
        .then(response => response.data.data)
        .then(response => {
          return response
        })
        .catch(err => {
          console.error(err)
          return []
        })
    },
    onEeditAccount (item) {
      if (item.type === 'expense' || item.type === 'income') {
        this.$router.push('/incomes-expenses/accounts/' + item.type + '/' + item.id + '/edit')
      } else if (item.type === 'asset' || item.type === 'liability') {
        this.$router.push('/assets-liabilities/accounts/' + item.type + '/' + item.id + '/edit')
      }
    },
    onDeleteAccount (item) {
      this.iReplaceAccountId = null
      this.iDestroyAccountId = item.id
      this.deleteAccountName = item.title
      this.deleteAccountType = item.type
      this.deleteAccountGroupId = item.group_id
      this.accountGroupOptions = []
      axios.get(`${process.env.VUE_APP_ROOT_API}/transactions?account_id=${item.id}`)
        .then(response => response.data.data)
        .then(responseData => {
          if (responseData.length > 0) {
            this.onDeleteMoveTransactions = true
          }
          if (Object.keys(item).length > 0 && Object.prototype.hasOwnProperty.call(item, 'id') && this.currentCOA.networth_account_id === item.id) {
            this.$refs.deleteModalNetworth.show()
          } else {
            this.$refs.deleteModal.show()
          }
        })
        .catch(err => {
          console.error(err)
          if (Object.keys(item).length > 0 && Object.prototype.hasOwnProperty.call(item, 'id') && this.currentCOA.networth_account_id === item.id) {
            this.$refs.deleteModalNetworth.show()
          }
        })
    },
    deleteAccountGroupModal (item) {
      let destroyItem = item
      this.accountGroups[item.type].map((group) => {
        if (group.id === item.id) {
          destroyItem = group
        }
      })

      this.iDestroyAccountGroupId = destroyItem.id
      this.deleteAccountGroupType = destroyItem.type
      this.deleteAccountName = destroyItem.title
      this.deleteAccountGroupName = destroyItem.title

      if (!destroyItem.accounts.length) {
        this.$refs.deleteAccountGroupModal.show()
        return 0
      }

      axios.get(`${process.env.VUE_APP_ROOT_API}/transactions?account_group_id=${destroyItem.id}`)
        .then(response => {
          if (response.data.data.length > 0) {
            this.$refs.deleteAccountGroupPreventModal.show()
          } else {
            this.$refs.deleteAccountGroupModal.show()
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    deleteAccountGroup () {
      axios.delete(`${process.env.VUE_APP_ROOT_API}/accounts/groups/${this.iDestroyAccountGroupId}`)
        .then(() => {
          this.$root.$bvToast.toast(this.$t(this.translationPath + 'alert_delete_account_group_' + this.deleteAccountGroupType + '_success_description', { name: this.deleteAccountGroupName }), {
            title: this.$t(this.translationPath + 'alert_delete_account_group_' + this.deleteAccountGroupType + '_success_title'),
            variant: 'success',
            id: 'DeleteAccountSuccessModal',
            solid: true,
            'auto-hide-delay': 3000
          })

          this.accountGroups[this.deleteAccountGroupType] = this.accountGroups[this.deleteAccountGroupType].filter((group) => {
            return group.id !== this.iDestroyAccountGroupId
          })

          // const accountTypeKey = this.deleteAccountGroupType === 'liability' ? 'liabilities' : this.deleteAccountGroupType + 's'
          // console.log(accountTypeKey, this.accountsGrouped)
          this.accountsGrouped[this.deleteAccountGroupType] = this.accountsGrouped[this.deleteAccountGroupType].filter((item) => {
            return (item.id !== this.iDestroyAccountGroupId && item.is_account_group) || (item.is_account && item.group_id !== this.iDestroyAccountGroupId)
          })

          this.tableData = this.createTableDataWithEmptyRows(this.accountsGrouped[this.deleteAccountGroupType])

          this.$refs.deleteAccountGroupModal.hide()
        })
        .catch(err => {
          this.$refs.deleteAccountGroupModal.hide()
          this.$bvToast.toast(this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'alert_del_account_group_fail' : 'alert_del_category_group_fail')), {
            title: this.$t(this.translationPath + (this.currentUser.strict_accounting_mode ? 'alert_del_account_group_title' : 'alert_del_category_group_title')),
            variant: 'danger',
            id: 'DeleteAccountFailedModal',
            solid: true,
            'auto-hide-delay': 3000
          })
          console.error(err)
        })
    },
    deleteAccount (eventData) {
      const self = this
      const apiEndPoint = eventData.selectedAccountId ? `${process.env.VUE_APP_ROOT_API}/accounts/destroy-and-move-entries/${self.iDestroyAccountId}/${eventData.selectedAccountId}` : `${process.env.VUE_APP_ROOT_API}/accounts/${self.iDestroyAccountId}`

      axios.delete(apiEndPoint)
        .then(() => {
          self.$root.$bvToast.toast(self.$t(self.translationPath + 'alert_delete_' + self.deleteAccountType + '_success_description', { name: self.deleteAccountName }), {
            title: self.$t(self.translationPath + 'alert_delete_' + self.deleteAccountType + '_success_title'),
            variant: 'success',
            id: 'DeleteAccountSuccessModal',
            solid: true,
            'auto-hide-delay': 3000
          })

          const accountTypeKey = self.deleteAccountType
          if (self.accounts) {
            self.accounts = self.accounts.filter((item) => item.id !== self.iDestroyAccountId)
          }
          self.accountsGrouped[accountTypeKey] = self.accountsGrouped[accountTypeKey].filter((item) => item.id !== self.iDestroyAccountId)
          self.accountGroups[self.deleteAccountType].map((group, index) => {
            if (group.id === self.deleteAccountGroupId) {
              self.accountGroups[self.deleteAccountType][index].accounts = group.accounts.filter((account) => account.id !== self.iDestroyAccountId)
            }
          })
          self.tableData = self.createTableDataWithEmptyRows(self.accountsGrouped[accountTypeKey])
          self.$refs.deleteModal.hide()
        })
        .catch(err => {
          self.$refs.deleteModal.hide()
          self.$bvToast.toast(self.$t(self.translationPath + (self.currentUser.strict_accounting_mode ? 'alert_del_account_fail' : 'alert_del_category_fail')), {
            title: self.$t(self.translationPath + (self.currentUser.strict_accounting_mode ? 'alert_del_account_title' : 'alert_del_category_title')),
            variant: 'danger',
            id: 'DeleteAccountFailedModal',
            solid: true,
            'auto-hide-delay': 3000
          })
          console.error(err)
        })
    },
    onAccountCreated (data) {
      const toastText = 'reports.other.account_plan.quick_add_account_modal.toast_success_' + data.storedAccount.type
      this.$bvToast.toast(this.$t(toastText, { title: data.storedAccount.title }), {
        title: this.$t('reports.other.account_plan.quick_add_account_modal.toast_title'),
        variant: 'success',
        solid: true
      })
      this.loadData()
    },
    resetModal () {
      this.iDestroyAccountGroupId = null
      this.iDestroyAccountId = null
      this.deleteAccountName = null
      this.deleteAccountType = null
      this.deleteAccountGroupType = null
      this.onDeleteMoveTransactions = false
    }
  }
}
