<template>
  <b-modal
    id="deleteAccountModal"
    centered
    ref="deleteModal"
    modal-class="spirecta-modal"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t('common.modals.delete.title_account', { account_title: itemName })}}
    </template>
    <template v-if="moveTransactions">
      <p>{{ currentUser.strict_accounting_mode ? $t(translationPath + 'select_message_account') : $t(translationPath + 'select_message_category')}}</p>
      <b-form-select v-model="selectedAccountId">
        <template v-if="accountGroupOptions.length > 0">
          <option :value="null">{{ currentUser.strict_accounting_mode ? $t(translationPath + 'select_default_account') : $t(translationPath + 'select_default_category')}}</option>
          <optgroup v-for="group in accountGroupOptions" :label="(group.type.charAt(0).toUpperCase() + group.type.slice(1)) + ' / ' + group.title" v-bind:key="group.code">
            <option v-for="options in group.accounts" :value="options.id" v-bind:key="options.code">{{ options.title | displayAccountTitle(options.code, currentCOA.prefix_account_title_with_code) }}</option>
          </optgroup>
        </template>
      </b-form-select>
      <template v-if="!selectedAccountId">
        <p class="mt-3">{{ $t(translationPath + 'or_type_date_to_delete_transactions', {date: this.date}) }}:</p>
        <b-form-input v-model="checkField" :placeholder="$t(translationPath + 'date_format_placeholder')"></b-form-input>
      </template>
    </template>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancel">{{ $t('common.no_cancel')}}</b-button>
      <b-button variant="danger"  @click="onYes" :disabled="isBtnYesDisabled">{{ $t('common.yes_delete')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'

export default {
  name: 'DeleteAccountModal',
  props: ['itemName', 'moveTransactions', 'itemType', 'iDestroyAccountId'],
  filters: {
    displayAccountTitle
  },
  data () {
    return {
      selectedAccountId: null,
      accountGroupOptions: [],
      checkField: '',
      date: new Date().toISOString().slice(0, 10),
      translationPath: 'common.modals.delete.'
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    isBtnYesDisabled () {
      if (this.moveTransactions && !this.selectedAccountId && this.checkField !== new Date().toISOString().slice(0, 10)) {
        return true
      }
      return false
    }
  },
  methods: {
    loadAccountGroups () {
      if (this.itemType && this.moveTransactions) {
        try {
          axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${this.itemType}&log=DeleteAccountModal`)
            .then(response => response.data.data)
            .then(res => {
              this.accountGroupOptions = res.filter(item => item.type === this.itemType).map(items => {
                const accounts = items.accounts.filter(accItem => Number(accItem.id) !== Number(this.iDestroyAccountId))
                return Object.assign({}, items, { accounts: accounts })
              }).filter(accountsMain => Object.keys(accountsMain.accounts).length > 0)
            })
        } catch (e) {
          console.error('Message:', e.message)
        }
      } else {
        this.accountGroupOptions = []
      }
    },
    reset () {
      this.selectedAccountId = null
      this.accountGroupOptions = []
      this.checkField = ''
    },
    onShow () {
      this.loadAccountGroups()
      this.$emit('show')
    },
    onHide () {
      this.reset()
      this.$emit('hide')
    },
    show () {
      this.$refs.deleteModal.show()
      this.$emit('show')
    },
    hide () {
      this.reset()
      this.$refs.deleteModal.hide()
      this.$emit('hide')
    },
    onCancel () {
      this.reset()
      this.$refs.deleteModal.hide()
      this.$emit('on-delete-cancel')
    },
    onYes () {
      const eventObject = { selectedAccountId: this.selectedAccountId }

      if (this.checkField === new Date().toISOString().slice(0, 10)) {
        eventObject.selectedAccountId = null
      }

      this.$emit('on-delete-confirm', eventObject)
    }
  },
  watch: {
    moveTransactions () {
      this.loadAccountGroups()
    },
    itemType () {
      this.loadAccountGroups()
    },
    isBtnYesDisabled: {
      handler () {
        this.$emit('year-disabled', this.isBtnYesDisabled)
      },
      immediate: true
    },
    selectedAccountId () {
      this.checkField = ''
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/modal.scss';
</style>
